import * as React from "react"
import { StorageConnector } from "../../storage"
import Button from "react-bootstrap/Button"

interface AuthBlockerProps {
  storage: StorageConnector
  children: React.ReactNode
}

export const AuthBlocker = (props: AuthBlockerProps) => {
  if (props.storage.requiresToken()) {
    return (
      <>
        <p>Aktion erforderlich</p>
        <Button
          onClick={() => props.storage.requestToken !== undefined && props.storage.requestToken()}
        >
          Zugriff erlauben
        </Button>
      </>
    )
  }
  return <div>{props.children}</div>
}
