import * as React from "react"
import { StorageConnector } from "../../storage"
import Button from "react-bootstrap/Button"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import { ProjectData } from "../../projectData"
import { FaDropbox, FaSave, FaFileMedical, FaFolderOpen, FaFlask } from "react-icons/fa"
import { OpenModal } from "./openModal"
import { SaveModal } from "./saveModal"

interface SelectProjectProps {
  storage: StorageConnector
  setStorage: (newStorage: StorageConnector) => void
  project: ProjectData
  setProject: (project: ProjectData) => void
}

const StorageIcon = (props: { storage: StorageConnector }) => {
  if (props.storage.getName() === "Dropbox") {
    return <FaDropbox/>
  }
  return <FaFlask/>
}

const SelectProject = (props: SelectProjectProps) => {
  const [showOpenModal, setShowOpenModal] = React.useState(false)
  const [showSave, setShowSave] = React.useState(false)
  const [saveModalProject, setSaveModalProject] = React.useState<ProjectData>()

  return (
    <ButtonGroup className="section-projects">
      <Button onClick={() => setShowOpenModal(true)}>
        <p><FaFolderOpen /></p>
        <p>Öffnen</p>
      </Button>
      <Button onClick={() => {
        setSaveModalProject(undefined)
        setShowSave(true)
      }}>
        <p><FaFileMedical /></p>
        <p>Neu</p>
      </Button>
      <Button onClick={() => {
        props.storage.saveProject(props.project)
          .then(() => console.log("Projekt gespeichert"))
          .catch(err => console.error(err))
      }}>
        <p><FaSave /></p>
        <p>Speichern</p>
      </Button>
      <Button onClick={() => {
        setSaveModalProject(props.project)
        setShowSave(true)
      }}>
        <p><StorageIcon storage={props.storage} /></p>
        <p>Speicherort ändern</p>
      </Button>

      <OpenModal
        visible={showOpenModal}
        storage={props.storage}
        onCancel={() => setShowOpenModal(false)}
        onSubmit={(project, storage) => {
          props.setStorage(storage)
          props.setProject(project)
          setShowOpenModal(false)
        }}
      />

      <SaveModal
        visible={showSave}
        project={saveModalProject}
        storage={props.storage}
        onCancel={() => setShowSave(false)}
        onSubmit={(project, storage) => {
          setShowSave(false)
          props.setStorage(storage)
          props.setProject(project)
        }}
      />
    </ButtonGroup>
  )
}

export default SelectProject
