import * as React from "react"
import Modal from "react-bootstrap/Modal"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { StorageConnector, allStorages } from "../../storage"
import { ProjectData, createEmptyProject } from "../../projectData"
import { AuthBlocker } from "./authBlocker"
const { useState } = React

interface SaveModalProps {
  visible: boolean
  project?: ProjectData
  storage: StorageConnector
  onCancel: () => void
  onSubmit: (project: ProjectData, storage: StorageConnector) => void
}

export const SaveModal = (props: SaveModalProps) => {
  const leftColWidth = 4
  const rightColWidth = 12 - leftColWidth

  const [storage, setStorage] = useState(props.storage)
  const [projectName, setProjectName] = useState("")

  const abort = () => {
    props.onCancel()
    setProjectName("")
  }

  return (
    <Modal
      show={props.visible}
      onHide={abort}
    >
      <Modal.Header closeButton>{props.project ? "Speicherort ändern" : "Neues Projekt anlegen"}</Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm={leftColWidth}>Speicherort</Form.Label>
            <Col sm={rightColWidth}>
              <Form.Control as="select"
                value={storage.getName()}
                onChange={e => {
                  const selected = allStorages.find(s => s.getName() === e.currentTarget.value)
                  if (selected !== undefined) {
                    setStorage(selected)
                  }
                }}
              >
                {allStorages.map(s => <option value={s.getName()} key={s.getName()}>{s.getName()}</option>)}
              </Form.Control>
            </Col>
          </Form.Group>

          <AuthBlocker storage={storage}>
            {props.project === undefined && <Form.Group as={Row}>
              <Form.Label column sm={leftColWidth}>Projektname</Form.Label>
              <Col sm={rightColWidth}>
                <Form.Control type="text" value={projectName} onChange={e => setProjectName(e.currentTarget.value)} />
              </Col>
            </Form.Group>}
            <Form.Group>
              <Button
                onClick={() => {
                  if (props.project !== undefined || projectName !== "") {
                    const project = props.project || createEmptyProject(projectName)
                    // does the project already exist?
                    storage.getProjects()
                      .then((names) => {
                        // does the project already exist?
                        if (names.find(n => n === project.meta.projectName) === undefined) {
                          storage.saveProject(project)
                            .then(() => {
                              props.onSubmit(project, storage)
                              setProjectName("")
                            })
                            .catch(err => console.error(err))
                        } else {
                          console.warn("Dieser Name wird bereits verwendet.")
                        }
                      })
                      .catch(err => console.error(err))
                  }
                }}
                disabled={props.project === undefined && projectName === ""}
              >
                Speichern
              </Button>
            </Form.Group>
          </AuthBlocker>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
