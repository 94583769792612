import * as React from "react"
import { useEffect, useState, useRef } from "react"
import * as d3 from "d3"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Button from 'react-bootstrap/Button'
import Pdf from '@mikecousins/react-pdf'
import { PlanData, PlanNote, createEmptyPlanNote } from "../../projectData"
import PlanNoteModal from "./planNoteModal"

import "./plan.less"
import { StorageConnector } from "../../storage"

export {default as NewPlanModal} from "./NewPlanModal"

// constrain infinite zooming
const minScale = 1
const maxScale = 8
const circleRadius = 10

interface PlanProps {
  data: PlanData
  updatePlan: (newPlan: PlanData | undefined, oldPlan: PlanData) => void
  storage: StorageConnector
  projectId: string
}

export const Plan = (props: PlanProps) => {
  const [selectedNote, setSelectedNote] = useState<PlanNote>()
  const [newNote, setNewNote] = useState<PlanNote>()
  const [foreignInitialised, setForeignInitialised] = useState(false)
  const [newPlanName, setNewPlanName] = useState(props.data.name)

  // called once when the component is mounted
  useEffect(() => {
    // setup the canvas and register the click event
    const svgNode = d3.select<HTMLElement, any>(".plan-container svg").node()
    const canvasContainer = d3.select<SVGElement, any>("#canvas-container")
    canvasContainer.select("canvas").attr("xmlns", "http://www.w3.org/1999/xhtml")
    canvasContainer
      .on("click", () => {
        const rect = svgNode!.getBoundingClientRect()
        const transform = d3.zoomTransform(svgNode!)
        const relPos = transform.invert([d3.event.x - rect.x, d3.event.y - rect.y])
        setNewNote(createEmptyPlanNote(relPos[0], relPos[1]))
      })
      // set initial scale. The pdf is rendered at maximum zoom scale, therefore
      // the initial scale is the invers value to go back to 100% scale
      .attr("transform", `translate(0, 0) scale(${1 / maxScale})`)
  }, [])

  // called every time when a note changes
  useEffect(() => {
    // D3 needs to update the note handles
    const svg = d3.select<HTMLElement, any>(".plan-container svg")
    const svgNode = svg.node()
    if (svgNode === null) {
      console.warn("svg node destroyed")
      return
    }
    const canvasContainer = svg.select<SVGElement>("#canvas-container")

    const transform = d3.zoomTransform(svgNode)
    const tooltip = d3.select<HTMLElement, any>(".tooltip")
      // set initial values for the tooltip transition
      .style("opacity", 0)
      .style("display", "none")
    const planContainer = d3.select<HTMLElement, any>(".plan-container").node()

    const circles = d3.select("g.note-handle-container")
      .selectAll(".note-handle")
      .data(props.data.notes)
    circles.enter()
      .append<SVGCircleElement>("circle")
      .attr("r", circleRadius)
      .attr("class", "note-handle")
      .on("click", d => {
        setSelectedNote(d)
        d3.event.stopPropagation()
      })
      // hover events to show a tooltip
      .on("mouseover", (d, i, groups) => {
        const rect = planContainer!.getBoundingClientRect()
        const circleRect = groups[i].getBoundingClientRect()
        tooltip
          .text(d.title)
          .style("left", `${circleRect.x - rect.x + 2.5 * circleRadius}px`)
          .style("top", `${circleRect.y - rect.y + circleRadius}px`)
          .style("display", null)
          .transition()
          .duration(200)
          .style("opacity", .9)
      })
      .on("mouseout", (d) => {
        tooltip.transition()
          .duration(500)
          .style("opacity", 0)
          .end()
          .then(() => tooltip.style("display", "none"))
      })
    circles.exit()
      .remove()

    const allCircles = svg.selectAll<SVGCircleElement, PlanNote>("circle.note-handle")
      .attr("transform", d => `translate(${transform.apply([d.position.x, d.position.y])})`)

    const zoomFunction = d3.zoom<HTMLElement, any>()
      .scaleExtent([minScale, maxScale])
      .on("zoom", () => {
        const { transform } = d3.event
        allCircles.attr("transform", d => `translate(${transform.apply([d.position.x, d.position.y])})`)
        canvasContainer.attr("transform", () => `translate(${transform.apply([0, 0])}) scale(${transform.k / maxScale})`)
      })

    svg.call(zoomFunction)
      // disable double click zoom
      .on("dblclick.zoom", null)
  }, [props.data.notes])

  const [bgData, setBgData] = useState<string>()
  // called when the background changes
  useEffect(() => {
    if (props.data.background) {
      props.storage.loadImg(props.projectId, props.data.background)
        .then(d => {
          setBgData(d)
        })
        .catch(() => console.error("Could not load image"))
    }
  }, [props.data.background])

  // the note that should be shown in the PlanNoteModal
  const editNote = newNote || selectedNote

  return (
    <div className="plan-container">
      <svg width="100%" height="100%">
        <g id="canvas-container">
          <foreignObject x={0} y={0}>
            {bgData !== undefined ?
              <Pdf
                file={bgData}
                page={1}
                scale={maxScale}
                onDocumentLoadFail={() => {
                  console.error("sample.pdf is missing in the dist folder")
                }}
                onPageRenderSuccess={() => {
                  if (!foreignInitialised) {
                    const canvas = d3.select("#canvas-container canvas")
                    // to render anything, foreignObject must be the same size as the canvas
                    console.warn(`h: ${canvas.attr("height")}, w: ${canvas.attr("width")}`)
                    d3.select("#canvas-container foreignObject")
                      .attr("height", canvas.attr("height"))
                      .attr("width", canvas.attr("width"))
                    setForeignInitialised(true)
                  }
                }}
              />
              : undefined}
          </foreignObject>
        </g>
        <g className="note-handle-container"/>
      </svg>

      <PlanNoteModal
        note={editNote}
        deletable={selectedNote !== undefined}
        onCancel={() => {
          setSelectedNote(undefined)
          setNewNote(undefined)
        }}
        onOK={(modifiedNote) => {
          if (newNote !== undefined) {
            // append new note
            console.log("append", modifiedNote)
            props.updatePlan({
              ...props.data,
              notes: [
                ...props.data.notes,
                modifiedNote,
              ],
            }, props.data)
            setNewNote(undefined)
          } else if (selectedNote !== undefined) {
            // update existing note
            props.updatePlan({
              ...props.data,
              notes: props.data.notes.map(n => n === editNote ? modifiedNote : n)
            }, props.data)
            console.log("update", modifiedNote)
            setSelectedNote(undefined)
          } else {
            console.warn("Unexpected code path")
          }
        }}
        onDelete={() => {
          props.updatePlan({
            ...props.data,
            notes: props.data.notes.filter(n => n !== editNote)
          }, props.data)
          setSelectedNote(undefined)
        }}
      />

      <div className="tooltip"/>

      <div className="plan-actions">
        <Button type="danger" onClick={() => props.updatePlan(undefined, props.data)}>
          Plan löschen
        </Button>
        <InputGroup>
          <FormControl value={newPlanName} onChange={(e) => {setNewPlanName(e.currentTarget.value)}}/>
          <InputGroup.Append>
            <Button disabled={newPlanName === ""} onClick={() => {
              if (newPlanName === "") {
                console.error("Name!")
              } else {
                props.updatePlan({
                  ...props.data,
                  name: newPlanName,
                }, props.data)
              }
            }}>Plan umbenennen</Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
    </div>
  )
}
