import * as React from "react"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Switch, Route, Link } from "react-router-dom"
import { DiaryItem } from "../../projectData"
import Item from "./item"

// copy & paste
function callback(key: any) {
  console.log(key);
}

interface DiaryProps {
  diaryItems: DiaryItem[];
  updateDiary: (newDiary: DiaryItem[]) => void
}

const Diary = (props: DiaryProps) => {
  return (
    <div>
      <Switch>

        {props.diaryItems.map(item => (
          // display a specific diary item
          <Route key={item.date} path={`/diary/${item.date}`}>
            <Breadcrumb>
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/diary" }}>Tagebuch</Breadcrumb.Item>
              <Breadcrumb.Item active>{item.date}</Breadcrumb.Item>
            </Breadcrumb>

            <Item itemData={item}/>
          </Route>
        ))}

        {/* when no diary item is selected */}
        <Route path="/diary">
          <Breadcrumb>
            <Breadcrumb.Item active>Tagebuch</Breadcrumb.Item>
          </Breadcrumb>

          {props.diaryItems.map(item => (
            <p key={item.date}>
              <Link to={`/diary/${item.date}`}>
                {item.date}
              </Link>
            </p>
          ))}
        </Route>

      </Switch>
    </div>
  )
}

export default Diary
