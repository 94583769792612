import { ProjectData } from "../projectData"
import dropbox from "./dropbox"
import mock from "./mock"

export interface StorageConnector {
  getName: () => string;
  requiresToken: () => boolean
  setToken?: (token: string) => void
  requestToken?: () => void
  loadProject: (projectId: string) => Promise<ProjectData>
  loadImg: (projectId: string, filename: string) => Promise<any>
  upload: (projectId: string, file: File) => Promise<string>
  getProjects: () => Promise<string[]>
  saveProject: (project: ProjectData) => Promise<unknown>
  removeProject: (projectId: string) => void
}

export const allStorages: StorageConnector[] = [mock, dropbox]
