// This is a mock project data and only intended for developing!

import { ProjectData } from "../../projectData"

const example: ProjectData = {
  meta: {
    projectName: "Testprojekt",
    client: "Mister Fritz",
    site: {
      city: "Neustadt",
      postalCode: 99923,
      street: "Hauptstraße 3a"
    },
  },
  diary: [
    {
      comments: "Das Haus steht fast. Es gibt noch ein wenig zu tun.",
      companies: [
        {
          name: "Die Profis",
          work: "Haus bauen",
          workers: 2,
        },
        {
          name: "Matthes",
          work: "Chillen und nen Kasten killen.",
          workers: 1,
        }
      ],
      date: "2020-05-27T21:12:31Z",
      weather: {
        temperature: 30,
        type: "sun",
      }
    },
    {
      comments: "Spatenstich.\nAlle waren gut drauf. Ich war aber auch alleine...",
      companies: [],
      date: "2019-11-20T11:00:45Z",
      weather: {
        temperature: -0.4,
        type: "rain",
      },
    },
    {
      comments: "Heute haben wir alles kaputt gemacht.",
      companies: [
        {
          name: "Die Profis",
          work: "Konstruktive Arbeit",
          workers: 302,
        }
      ],
      date: "2020-03-24T11:00:45Z",
      weather: {
        temperature: 21.3,
        type: "cloudy",
      },
    }
  ],
  plans: [
    {
      name: "Hauptplan",
      background: "doge.pdf",
      notes: [
        {
          images: [],
          text: "Hier muss ein Haus hin.\nEs ist wichtig, dass das wirklich ein Haus wird. Sonst wäre das irgendwie ein kleines bisschen doof.",
          title: "Haus bauen!",
          position: {
            x: 23.66,
            y: 420,
          },
        },
        {
          images: [
            "img1.png",
            "img2.jpg",
            "img3.svg",
          ],
          text: "Irgendwer hat hier aber heftig gepfuscht!",
          title: "Sehr interessant",
          position: {
            x: 230.66,
            y: 120,
          },
        }
      ],
    }
  ],
}

export default example
