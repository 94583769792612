import { Dropbox } from "dropbox"
import {sha256} from "hash.js"
// var sha512 = require('hash.js/lib/hash/sha/512');
// sha512().update('abc').digest('hex');
import { StorageConnector } from "."
import { ProjectData, getAllFilenames } from "../projectData"

class DropboxConnector implements StorageConnector {
  readonly dbx = new Dropbox({ fetch, clientId: "6wpncebtr0wbfp1" })

  getName = () => "Dropbox"

  requiresToken = () => {
    return this.dbx.getAccessToken() === undefined
  }
  requestToken = () => {
    // the `site` parameter is handled in the setupTokenReceiver function in authHandler.tsx
    window.open(this.dbx.getAuthenticationUrl(`${window.location.protocol}//${window.location.host}/auth?site=dbx`))
  }
  setToken = (token: string) => {
    this.dbx.setAccessToken(token)
  }

  getProjects = (): Promise<string[]> => new Promise((resolve, reject) => {
    this.dbx.filesListFolder({ path: "" })
      .then((response) => {
        // TODO: handle bad input
        resolve(response.entries.filter(e => e[".tag"] === "folder").map(v => v.name))
      })
      .catch((error) => {
        reject(error)
      })
  })

  loadProject = (projectId: string): Promise<ProjectData> => new Promise((resolve, reject) => {
    this.dbx.filesDownload({ path: `/${projectId}/${projectId}.json` })
      .then((response) => {
        const reader = new FileReader()
        reader.addEventListener("loadend", () => {
          // reader.result beinhaltet den Inhalt des Blobs
          resolve(JSON.parse(<string>reader.result))
        })
        reader.readAsText((<any>response).fileBlob)
      })
      .catch((error) => {
        reject(error)
      })
  })
  loadImg = (projectId: string, filename: string): Promise<any> => new Promise((resolve, reject) => {
    this.dbx.filesDownload({ path: `/${projectId}/${filename}` })
      .then((response) => {
        const reader = new FileReader()
        reader.addEventListener("loadend", () => {
          resolve(reader.result)
        })
        reader.readAsDataURL((response as any).fileBlob)
      })
      .catch(err => reject(err))
  })
  upload = (projectId: string, file: File) => new Promise<string>((resolve, reject) => {
    // compute the sha256 hash, this will be the new filename
    const filenameSplit = file.name.split(".")
    let fileExt = ""
    if (filenameSplit.length > 1) {
      fileExt = `.${filenameSplit[filenameSplit.length - 1]}`
    }
    const hashedName = sha256().update(file.name).digest("hex") + fileExt

    this.dbx.filesUpload({
      path: `/${projectId}/${hashedName}`,
      contents: file,
    })
      .then(() => resolve(hashedName))
      .catch(err => reject(err))
  })

  saveProject = (project: ProjectData) => new Promise((resolve, reject) => {
    if (project.meta.projectName === undefined) {
      reject({ type: "noname" })
    } else {
      this.dbx.filesUpload({
        path: `/${project.meta.projectName}/${project.meta.projectName}.json`,
        contents: JSON.stringify(project, null, 2),
        // TODO: handle unintended overwrite
        mode: { ".tag": "overwrite" },
      })
        .then(() => {
          // now it's time to remove unnecessary files
          const allFilenames = [...getAllFilenames(project), `${project.meta.projectName}.json`]

          this.dbx.filesListFolder({ path: `/${project.meta.projectName}` })
            .then((response) => {
              // select the unnecessary files
              const toRemove = response.entries
                .map(entry => entry.name)
                .filter(filename => allFilenames.find(fn => fn === filename) === undefined)

              // remove them
              Promise.all(toRemove.map(filename => this.dbx.filesDeleteV2({ path: `/${project.meta.projectName}/${filename}` })))
                .then(() => resolve())
                .catch(err => {
                  // could not sanitise files but saving succeeded nevertheless
                  console.warn("could not sanitize files", err)
                  resolve()
                })
            })
            .catch(err => {
              // could not sanitise files but saving succeeded nevertheless
              console.warn("could not sanitize files", err)
              resolve()
            })
        })
        .catch(error => reject({ type: undefined, details: error }))
    }
  })

  removeProject = () => {
    console.warn("We don't do that yet")
  }
}

export default new DropboxConnector()
