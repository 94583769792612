import * as React from "react"
import { useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { MainMenu } from "./mainMenu"
import Project from "./Project"
import { ProjectData } from "../projectData"
import { useLocation } from "react-router-dom"

export const App = () => {
  const [project, setProject] = useState<ProjectData>()
  const [showNewPlan, setShowNewPlan] = useState(false)
  const location = useLocation()
  useEffect(() => setShowMenu(false), [location])
  const [showMenu, setShowMenu] = useState(false)

  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => { window.removeEventListener("resize", handleResize) }
  }, [])


  const breakpoint = 600
  const mobile = width < breakpoint

  const mainMenuProps = {
    project,
    onNewPlan: () => setShowNewPlan(true),
    onCloseDrawer: mobile ? (() => setShowMenu(false)) : undefined,
  }
  const contentProps = {
    projectData: project,
    setProjectData: setProject,
    showNewPlan,
    setShowNewPlan,
  }

  // if (mobile) {
  //   return (
  //     <Layout style={{ minHeight: '100vh' }}>
  //       <Layout.Header id="mobile-header">
  //         <h2>
  //           <a style={{ color: "white" }} onClick={() => setShowMenu(prev => !prev)}>
  //             <MenuUnfoldOutlined/> Menü
  //           </a>
  //         </h2>
  //       </Layout.Header>
  //       <Layout.Content>

  //       <Drawer
  //         closable={false}
  //         className="mobile-site"
  //         placement="left"
  //         onClose={() => setShowMenu(false)} visible={showMenu}
  //       >
  //         <MainMenu {...mainMenuProps}/>
  //       </Drawer>
  //         <Project {...contentProps}/>
  //       </Layout.Content>
  //     </Layout>
  //   )
  // }
  return (
    <main>
      <Row className="main-row">
        <Col xs="2" className="sidebar">
          <MainMenu {...mainMenuProps}/>
        </Col>
        <Col className="content">
          <Project {...contentProps} />
        </Col>
      </Row>
    </main>
  )
}