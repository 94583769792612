import * as React from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"
import { StorageConnector, allStorages } from "../../storage"
import { ProjectData } from "../../projectData"
import { AuthBlocker } from "./authBlocker"

interface OpenModalProps {
  visible: boolean
  storage: StorageConnector
  onCancel: () => void
  onSubmit: (project: ProjectData, storage: StorageConnector) => void
}

export const OpenModal = (props: OpenModalProps) => {
  const leftColWidth = 6
  const rightColWidth = 24 - leftColWidth

  const [storage, setStorage] = React.useState(props.storage)
  const [projects, setProjects] = React.useState<ProjectData[]>([])
  const [selectedProject, setSelectedProject] = React.useState("")

  React.useEffect(() => {
    // reset until it's loaded
    setProjects([])
    if (!storage.requiresToken()) {
      storage.getProjects()
        .then((names) => {
          Promise.all(names.map(n => storage.loadProject(n)))
            .then((projects) => {
              setProjects(projects)
              if (projects.length > 0) {
                setSelectedProject(projects[0].meta.projectName)
              }
            })
            .catch(error => console.error(error))
        })
        .catch((error) => {
          console.error(error.error)
        })
    }
  }, [storage])

  return (
    <Modal
      show={props.visible}
      onHide={props.onCancel}
    >
      <Modal.Header closeButton>Projekt öffnen</Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label column sm="2">Speicherort</Form.Label>
            <Form.Control as="select"
              value={storage.getName()}
              onChange={e => {
                const selected = allStorages.find(s => s.getName() === e.currentTarget.value)
                if (selected !== undefined) {
                  setStorage(selected)
                }
              }}
            >
              {allStorages.map(s => <option value={s.getName()} key={s.getName()}>{s.getName()}</option>)}
            </Form.Control>
          </Form.Group>

          <AuthBlocker storage={storage}>
            <Form.Group>
              <Form.Label column sm="2">Projekt</Form.Label>
              <Form.Control as="select"
                disabled={projects.length === 0}
                value={selectedProject}
                onChange={e => { setSelectedProject(e.currentTarget.value) }}
              >
                {projects.map(p => <option value={p.meta.projectName} key={p.meta.projectName}>{p.meta.projectName}</option>)}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Button
                onClick={() => {
                  const project = projects.find(p => p.meta.projectName === selectedProject)
                  if (project !== undefined) {
                    props.onSubmit(project, storage)
                  }
                }}
                type="primary"
              >
                Öffnen
            </Button>
            </Form.Group>
          </AuthBlocker>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
