import * as React from "react"
import Nav from "react-bootstrap/Nav"
import { FaHome, FaChartPie, FaBook, FaPlus, FaFile, FaTimes } from "react-icons/fa"
import { Link, useLocation } from "react-router-dom"
import { ProjectData } from "../projectData"

interface MainMenuProps {
  project: ProjectData | undefined
  onNewPlan: () => void
  onCloseDrawer?: () => void
}

// I honestly don't know why we can't convert this to a React component
// but somehow using a React component results in a weird looking menu item
const getPlanItem = (name: string, index: number) => {
  return (
    <Nav.Link key={index} eventKey={`/plan/${index}`} to={`/plan/${index}`} as={Link}>
      <FaFile/>
      <span>{name}</span>
    </Nav.Link>
  )
}

const getEventKey = () => {
  const pathname = useLocation().pathname
  if (pathname.startsWith("/diary")) {
    return "/diary"
  }
  return pathname
}

export const MainMenu = (props: MainMenuProps) => (
  <Nav
  className="flex-column menubar"
  variant="pills"
  activeKey={getEventKey()}
>
  <Nav.Link eventKey="/projects" to="/projects" as={Link}>
    <FaHome />
    <span>{props.project?.meta.projectName}</span>
  </Nav.Link>
  <Nav.Link eventKey="/meta" to="/meta" as={Link}>
    <FaChartPie />
    <span>Übersicht</span>
  </Nav.Link>

  <Nav.Link eventKey="/diary" to="/diary" as={Link}>
      <FaBook />
      <span>Tagebuch</span>
  </Nav.Link>

  {props.project?.plans.map((p, i) => getPlanItem(p.name, i))}

  <Nav.Link eventKey="add" onClick={props.onNewPlan}>
    <FaPlus />
    <span>Neuer Plan</span>
  </Nav.Link>

  {props.onCloseDrawer !== undefined && <Nav.Link onClick={props.onCloseDrawer}>
    <FaTimes/>
    <span>Schließen</span>
  </Nav.Link>}
</Nav>
)
