import * as React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Switch, Route } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css'

import "./index.css"
import { AuthHandler } from './components/authHandler'
import { App } from './components/App'

render(
  <BrowserRouter>
    <Switch>
      <Route path="/auth">
        <AuthHandler/>
      </Route>
      <Route path="*">
        <App />
      </Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
)
