import * as React from "react"
import { DiaryItem } from "../../projectData"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import ListGroup from "react-bootstrap/ListGroup"

interface IItemProps {
  itemData: DiaryItem;
}

function onChangeTemperature(value: any) {
  console.log("changed", value)
}
function onChangeWeatherType(value: any) {
  console.log("weather", value)
}
function onChangeComment(value: any) {
  console.log("comment", value)
}

const Item = (props: IItemProps) => {
  const companiesItems = props.itemData.companies.map(item => <p>{item.name}{item.work}{item.workers}</p>)
  return (
    <div>
      <div>
        <h2>Kommentar</h2>
        <Form.Control as="textarea"
          value={props.itemData.comments}
          onChange={onChangeComment}
          rows={4} />
      </div>
      <div>
        <h2>Unternehmen</h2>
        <ListGroup>
          {props.itemData.companies.map(item => (
            <ListGroup.Item key={item.name}>
              <h4>{item.name}</h4>
              <p>{item.work}</p>
              <p>Mitarbeiter: {item.workers}</p>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
      <div>
        <h2>Wetter</h2>
        <InputGroup>
          <FormControl value={props.itemData.weather.temperature} onChange={onChangeTemperature} />
          <FormControl value={props.itemData.weather.type} onChange={onChangeWeatherType} />
        </InputGroup>
      </div>
    </div>
  )
}

export default Item