// TODO: USING COOKIES TO STORE TOKENS IS BAD PRACTICE!
import * as React from "react"
import { parse } from "query-string"
import dropbox from "../storage/dropbox"

export const AuthHandler = () => {
  React.useEffect(() => {
    const token = parse(window.location.hash).access_token
    // site is defined in the requestToken function of the storage connector
    // this is used to determine which storage we are actually authorising right now
    const site = parse(window.location.search).site
    const opener: Window = window.opener
    opener.postMessage({
      site,
      access_token: token,
    }, "*")
    window.close()
  }, [])

  return <div>Processing...</div>
}

// https://stackoverflow.com/a/25490531
const getCookieValue = (a: string) => {
  var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

// TODO: USING COOKIES TO STORE TOKENS IS BAD PRACTICE!

/** Listen to access tokens sent via postMessage from the auth window. */
export const setupTokenReceiver = () => {
  window.addEventListener("message", (e) => {
    if (e.origin === window.location.origin) {
      if (e.data.site === "dbx") {
        dropbox.setToken(e.data.access_token)
        document.cookie = `dbx=${e.data.access_token}`
        console.log("Dropbox authorisiert")
      } else {
        console.error("Authorisierung fehlgeschlagen")
        console.warn("auth result:", e.data)
      }
    } else {
      console.warn("Unknown sender origin:", e.origin)
    }
  })

  // also read all tokens that were stored in a cookie
  const dbxCookieToken = getCookieValue("dbx")
  dbxCookieToken && dropbox.setToken(dbxCookieToken)
}
