import { StorageConnector } from "."
import { ProjectData } from "../projectData"
import example1 from "./mockProjects/example1"
import example2 from "./mockProjects/example2"
import { doge, lolcat } from "./mockProjects/images"

const examples: {[key: string]: ProjectData} = {
  example1,
  example2,
}

const connector: StorageConnector = {
  getName: () => "mock",
  requiresToken: () => false,
  loadProject(projectId: string) {
    return new Promise<ProjectData>((resolve) => {
      resolve(examples[projectId])
    })
  },
  loadImg: (projectId, filename) => new Promise((resolve, reject) => {
    if (filename == "doge.pdf") {
      resolve(doge)
    } else if (filename == "lolcat.pdf") {
      resolve(lolcat)
    } else {
      reject("Not implemented")
    }
  }),
  upload: () => new Promise(resolve => resolve("yay")),
  getProjects() {
    return new Promise((res) => {
      res(Object.keys(examples))
    })
  },
  saveProject: () => new Promise((resolve, reject) => reject("Not implemented")),
  removeProject() {},
}

export default connector
