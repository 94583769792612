export interface MetaData {
  projectName: string
  client: string
  site: {
    city: string
    street: string
    postalCode?: number
  }
}

interface DiaryCompany {
  /** The name of the company */
  name: string
  /** Number of workers present */
  workers: number
  /** Description of the work that was done */
  work: string
}

export interface DiaryItem {
  /** Use a timestamp */
  date: string
  companies: DiaryCompany[]
  weather: {
    /** In celsius */
    temperature: number
    /** E.g. cloudy, sunny, rain */
    type: string
  }
  /** What happend today */
  comments: string
}

export interface PlanNote {
  title: string
  text: string
  /** Hashes to images (maybe other attachments) */
  images: string[]
  position: {
    x: number
    y: number
  }
}

export interface PlanData {
  /** The name of the plan */
  name: string
  /** The file hash of the background file */
  background: string
  notes: PlanNote[]
}

export interface ProjectData {
  meta: MetaData
  diary: DiaryItem[]
  plans: PlanData[]
}

export const createEmptyProject = (projectName: string): ProjectData => ({
  meta: {
    projectName,
    client: "",
    site: {
      city: "",
      street: "",
    },
  },
  diary: [],
  plans: [],
})

export const createEmptyPlanNote = (xPos: number, yPos: number): PlanNote => ({
  title: "",
  text: "",
  images: [],
  position: {
    x: xPos,
    y: yPos,
  },
})

export const getAllFilenames = (project: ProjectData): string[] => {
  const backgrounds = project.plans.map(plan => plan.background)

  const flatten = <T>(array: T[][]) => array.reduce((flattend, curr) => [...flattend, ...curr], [])
  const notes = flatten(flatten(project.plans.map(plan => plan.notes.map(note => note.images))))

  return [...backgrounds, ...notes]
}
