import * as React from "react"
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"

interface NewPlanModalProps {
  visible: boolean
  onCancel: () => void
  onSubmit: (name: string, background: File) => void
}

const NewPlanModal = (props: NewPlanModalProps) => {
  const [name, setName] = React.useState("")
  const [background, setBackground] = React.useState<File>()

  // the button click event is redirected to an invisible file input element
  const uploadRef = React.useRef<HTMLInputElement>(null)

  return (
    <Modal
      show={props.visible}
      onHide={() => {
        setName("")
        setBackground(undefined)
        props.onCancel()
      }}
    >
      <Modal.Header closeButton>Neue Plankommentierung</Modal.Header>
      <Modal.Body>
        {/* invisible upload button to handle uploads */}
        <input
          ref={uploadRef}
          type="file"
          style={{ display: "none" }}
          onChange={e => {
            if (e.currentTarget.files && e.currentTarget.files.length > 0) {
              const file = e.currentTarget.files[0]
              if (file.type === "application/pdf") {
                setBackground(file)
              } else {
                console.warn("not pdf:", file.type)
              }
            }
          }}
        />
        <InputGroup>
          <FormControl placeholder="Name" value={name} onChange={e => setName(e.currentTarget.value)}/>
          <InputGroup.Append>
            <Button onClick={() => uploadRef.current?.click() }>Hintergrund festlegen</Button>
          </InputGroup.Append>
        </InputGroup>
        <span>{background && background.name}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={name === "" || background === undefined}
          onClick={() => {
            if (name !== "" && background !== undefined) {
              props.onSubmit(name, background)
              setName("")
              setBackground(undefined)
            }
          }}
        >OK</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewPlanModal
