import * as React from "react"
import FormControl from "react-bootstrap/FormControl"
import { MetaData } from "../../projectData"
const { useState } = React

interface MetaProps {
  data: MetaData;
}

const Meta = (props: MetaProps) => {
  const [clientName, setClientName] = useState("Bauherr")
  return (
    <div>
      <h1>Übersicht</h1>
      <p><FormControl value={clientName} onChange={e => setClientName(e.currentTarget.value)}/></p>
    </div>
  )
}

export default Meta
