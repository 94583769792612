import * as React from "react"
import { useEffect, useState, useRef } from "react"
import Form from "react-bootstrap/Form"
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { PlanNote } from "../../projectData"

// TODO: support more
const imageTypes = ["image/png", "image/jpeg"]

interface PlanNoteProps {
  note?: PlanNote
  deletable: boolean
  onCancel: () => void
  onOK: (modifiedNote: PlanNote) => void
  onDelete: () => void
}

const PlanNoteModal = (props: PlanNoteProps) => {
  const [clone, setClone] = useState<PlanNote>()
  const setComment = (comment: string) => {
    if (clone !== undefined) {
      setClone({ ...clone, text: comment })
    }
  }
  const setTitle = (title: string) => {
    if (clone !== undefined) {
      setClone({ ...clone, title: title })
    }
  }
  const addImg = (hash: string) => {
    if (clone !== undefined) {
      setClone({
        ...clone,
        images: [...clone.images, hash],
      })
    }
  }
  const removeImg = (hash: string) => {
    if (clone !== undefined) {
      setClone({
        ...clone,
        images: clone.images.filter(v => v !== hash),
      })
    }
  }

  useEffect(() => setClone(props.note), [props.note])

  const uploadRef = useRef<HTMLInputElement>(null)

  let content: JSX.Element | undefined = undefined
  if (clone !== undefined) {
    content = (
      <div>
        <Form.Control
          as="textarea"
          rows={4}
          placeholder="Kommentare"
          value={clone.text}
          onChange={e => setComment(e.currentTarget.value)}
        />
        <h3>Bilder</h3>
        <ul>
          {clone.images.map(imgHash => (
            <li key={imgHash}>
              {imgHash}
              <a onClick={() => removeImg(imgHash)}>Entfernen</a>
            </li>
          ))}
        </ul>
        <input
          ref={uploadRef}
          type="file"
          style={{ display: "none" }}
          onChange={e => {
            if (e.currentTarget.files && e.currentTarget.files.length > 0) {
              const file = e.currentTarget.files[0]
              if (imageTypes.findIndex(v => v === file.type) >= 0) {
                // TODO: use hash instead of filename
                addImg(file.name)
              } else {
                console.warn("not an image:", file.type)
              }
            }
          }}
        />
        <Button onClick={() => uploadRef.current?.click()}>+ Bild hinzufügen</Button>
      </div>
    )
  }

  const handleDelete = () => props.onDelete()

  const handleOK = () => {
    if (clone !== undefined) {
      props.onOK(clone)
    }
  }

  const titleElement = <input placeholder="Titel" value={clone?.title} onChange={e => setTitle(e.currentTarget.value)} />

  return (
    <Modal
      show={props.note !== undefined}
      onHide={props.onCancel}
    >
      <Modal.Header>{titleElement}</Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        {props.deletable && <Button key="delete" variant="danger" onClick={handleDelete}>
          Löschen
        </Button>}
        <Button key="back" variant="secondary" onClick={props.onCancel}>
          Abbrechen
        </Button>
        <Button key="submit" variant="primary" onClick={handleOK}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PlanNoteModal
