import * as React from "react"
import { useState, useEffect } from "react"
import Alert from "react-bootstrap/Alert"
import Spinner from "react-bootstrap/Spinner"

import Diary from "./diary/diary"
import Meta from "./meta/meta"
import { Plan, NewPlanModal } from "./plan"
import SelectProject from "./projects"

import { allStorages } from "../storage"
import { Switch, Route, useLocation, Redirect, useHistory } from "react-router-dom"
import { parse } from "query-string"
import { setupTokenReceiver } from "./authHandler"
import { ProjectData, PlanData, DiaryItem } from "../projectData"

interface ProjectProps {
  projectData: ProjectData | undefined
  setProjectData: React.Dispatch<React.SetStateAction<ProjectData | undefined>>
  showNewPlan: boolean
  setShowNewPlan: React.Dispatch<React.SetStateAction<boolean>>
}

const Project = (props: ProjectProps) => {
  const location = useLocation()
  const history = useHistory()

  const [storage, setStorage] = useState(allStorages[0])
  const { projectData, setProjectData } = props
  const updatePlan = (newPlan: PlanData | undefined, oldPlan: PlanData) => {
    if (projectData) {
      if (newPlan) {
        // replace oldPlan with newPlan
        setProjectData({
          ...projectData,
          plans: projectData.plans.map(p => p === oldPlan ? newPlan : p),
        })
      } else {
        // if newPlan is not set, oldPlan is deleted
        setProjectData({
          ...projectData,
          plans: projectData.plans.filter(p => p !== oldPlan)
        })
        // show the meta view
        history.push("/meta")
      }
    }
  }
  const updateDiary = (newDiary: DiaryItem[]) => {
    if (projectData) {
      setProjectData({
        ...projectData,
        diary: newDiary,
      })
    }
  }

  // wether to show the newPlan modal or not
  const { showNewPlan, setShowNewPlan } = props

  // steps to do once at startup
  useEffect(() => {
    storage.loadProject("example1")
      .then(project => setProjectData(project))
      .catch(error => console.error(error))

    setupTokenReceiver()
  }, [])

  // as long as there is no project data, show a loading icon
  if (projectData === undefined) {
    return (
      <div className="loading-container">
        <Spinner animation="border"/>
        <p>Projekt wird geladen</p>
      </div>
    )
  }

  return (
    <div className={location.pathname.startsWith("/plan") ? "content-plan" : "content-default"}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/projects"/>
          </Route>
          <Route path="/projects">
            <SelectProject
              storage={storage}
              setStorage={newStorage => setStorage(newStorage)}
              project={projectData}
              setProject={p => setProjectData(p)}
            />
          </Route>
          <Route path="/meta">
            <Meta data={projectData.meta} />
          </Route>
          <Route path="/diary">
            <Diary updateDiary={updateDiary} diaryItems={projectData.diary} />
          </Route>
          {projectData.plans.map((plan, i) => (
            <Route key={plan.name} path={`/plan/${i}`}>
              <Plan projectId={projectData.meta.projectName} storage={storage} updatePlan={updatePlan} data={plan}/>
            </Route>
          ))}
          <Route path="*">
            <Alert variant="danger">Missing</Alert>
            <code>{JSON.stringify(parse(location.hash), undefined, 2)}</code>
          </Route>
        </Switch>

        <NewPlanModal
          visible={showNewPlan}
          onCancel={() => setShowNewPlan(false)}
          onSubmit={(name, backgroundFile) => {
            storage.upload(projectData.meta.projectName, backgroundFile)
              .then(hashedFilename => {
                setProjectData({
                  ...projectData,
                  plans: [
                    ...projectData.plans,
                    {
                      name,
                      background: hashedFilename,
                      notes: [],
                    }
                  ],
                })
                // show the newly created plan
                history.push(`/plan/${projectData.plans.length}`)
                // disable the modal
                setShowNewPlan(false)
              })
              .catch(err => {
                console.error(err)
                setShowNewPlan(false)
            })
          }}
        />
        </div>
  )
}

export default Project
